<template>
	<section id="tw-view-faqs" class="tw-content col-12">
		<div class="bg-white">
			<div class="p-4">
				<div class="tw-table-header row">
					<div class="tw-table-search-wrapper col-lg-4 text-end">
                        <input type="search" class="form-control" v-model="querySearch" placeholder="Search faqs" />
                        <span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
                    </div>
					<div class="col text-end mt-3 mt-lg-0 d-flex">
						<button class="tw-default-bg-color text-white d-block d-lg-flex align-items-center ms-auto justify-content-center px-3 me-auto me-lg-0" v-if="selected" @click.prevent="bulkDelete">
							<i class="fa fa-trash"></i>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1">Delete {{ selected }} Faqs</span>
						</button>
						<button class="tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0" @click="openCreateFaqModal">
							<span class="d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
								<i class="fas fa-plus"></i>
							</span>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1 pe-5">Create Faq</span>
						</button>
					</div>
				</div>
				<div class="table-responsive">
					<table ng-if="!vm.permissions.notices" st-table="vm.results" st-pipe="vm.loadResults" class="tw-table table text-nowrap">
						<thead>
							<tr>
								<th class="tw-table-checkbox-th"></th>
								<th>Picture</th>
								<th st-sort="title">
									<span class="cursor-pointer d-flex align-items-center" @click="setOrder('title')">
										<span class="me-3">Title</span>
										<span v-if="orderBy != 'title'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up me-2">
												<span class="d-block"></span>
											</span>
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'title' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'title' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
									</span>
								</th>
								<th st-sort="date">
									<span class="cursor-pointer d-flex align-items-center" @click="setOrder('date')">
										<span class="me-3">Date</span>
										<span v-if="orderBy != 'date'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up me-2">
												<span class="d-block"></span>
											</span>
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'date' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'date' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
									</span>
								</th>
								<th>Category</th>
								<th></th>
							</tr>
						</thead>

						<tbody class="position-relative">
							<div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
								<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
							</div>
							<tr v-for="(faq, index) in faqs" :class="faq.type == 'tw_alerts' && 'red'" :key="index">
								<td class="tw-checkbox"><input :id="'faq' + faq.id" type="checkbox" @click="countSelected(index, $event)" value="1" :checked="faq.checked"/><label :for="'faq' + faq.id"></label></td>
								<td class="ps-4">
									<img v-if="faq.featured_media" :src="faq.featured_media.media_details.sizes.thumbnail.source_url" :alt="faq.title" height="53" width="55">
									<span v-else class="d-flex align-items-center">
										<span v-html="defaultImage"></span> <a class="tw-red-color ms-md-3 fw-bold" href="#" @click.stop.prevent="openCreateFaqModal($event, faq.id)">Click to add</a>
									</span>
								</td>
								<td>
									<a class="tw-table-title-column" href="" @click.stop.prevent="openCreateFaqModal($event, faq.id)" v-html="sliceString(faq.title, 70)" data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" :title="faq.title"></a> <small v-if="faq.status != 'publish'" class="bg-info text-white px-1 rounded text-capitalize">{{ faq.status }}</small>
								</td>
								<td>{{ moment(faq.date).format('ll') }}</td>
								<td>
									<div>
										<span v-for="(category, index) in faq.categories" :key="index"><span v-html="category.name"></span><span v-if="(index + 1) != faq.categories.length">,</span> </span>
									</div>
								</td>
								<td class="text-end column-actions">
									<div class="d-flex justify-content-end">
										<a href="" @click.prevent="removeFaq(faq)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span></a>
										<a href="" @click.stop.prevent="openCreateFaqModal($event, faq.id)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span></a>
									</div>
								</td>
							</tr>
						</tbody>

						<tbody v-if="requestResolved && (!faqs || !faqs.length)">
							<tr>
								<td colspan="6" class="text-center">Nothing to show, no results found.</td>
							</tr>
						</tbody>

						<tfoot v-if="faqs.length">
							<tr>
								<td colspan="3">
									Rows per page
									<select v-model="perPage" class="border">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
									</select>
								</td>
								<td colspan="4" class="tw-table-pagination text-end">

									<Pagination
										:totalItems="numberOfFaqs"
										:currentPage="page"
										:perPage="perPage"
										@pageChanged="setPage"
										:goButton="false"
										styled="centered"
										borderActiveColor=""
										borderTextActiveColor="text-white"
									/>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
		<CreateFaqModal ref="CreateFaqModal" :editing="editing" :editingFaqId="editingFaqId" @faqSaved="handleFaqSaved" @modalHidden="handleModalHidden" />
	</section>
</template>

<script>
	import siteMixin from '@/mixins/siteMixin';
	import Pagination from '@/components/pagination/Pagination'
	import CreateFaqModal from '@/components/modals/CreateFaqModal';
	import helperMixin from '@/mixins/helperMixin';
	import wordpressMixin from '@/mixins/wordpressMixin';
	import cms6Mixin from '@/mixins/cms6Mixin';
    import { Tooltip } from "bootstrap";

	let site = localStorage.getObject('site');

	let mixins;

	if (site.type == 'wordpress') {
		mixins = [wordpressMixin,siteMixin,helperMixin];
	} else if(site.type == 'cms6') {
		mixins = [cms6Mixin,siteMixin,helperMixin];
	}
	export default {
		name: 'Faqs',
		components: {
			Pagination,
			CreateFaqModal,
		},
		data: () => ({
			faqs: [],
			numberOfShowedFaqs: 0,
			numberOfFaqs: 0,
			numberOfPages: 0,
			querySearch: '',
			requestResolved: false,
			page: 1,
			perPage: 10,
			order: 'desc',
			orderBy: 'date',
			selected: 0,
			defaultImage: '<div class="tw-defaul-featured-img"><i class="fas fa-image"></i></div>',
			editing: false,
			editingFaqId: null,
			site_type: null
		}),
		mounted() {
			this.site_type = this.getSiteType();
			this.setFaqs();
		},
        updated() {
            let tooltopElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

            tooltopElements.forEach(element => {

                let tooltip = Tooltip.getInstance(element);

                if (tooltip) {
                    tooltip.dispose();
                }

                if (!Tooltip.getInstance(element)) {
                    new Tooltip(element)
                }
            });
        },
		watch: {
			perPage: function () {
				this.page = 1;
				this.setFaqs();
			},
			page: function () {
				this.setFaqs();
			},
			orderBy: function () {
				this.setFaqs();
			},
			order: function () {
				this.setFaqs();
			},
			querySearch: function () {
				this.setFaqs();
			}
		},
		methods: {
			async setFaqs() {
				this.requestResolved = false;

				this.$el.querySelectorAll("td.tw-checkbox input").forEach( function(checkbox){
					checkbox.checked = false;
				});
				this.selected = 0;

				let res = await this.getFaqs(this.page, this.perPage, this.order, this.orderBy, this.querySearch);
				if (res && res.status === 200) {
					this.requestResolved = true;
					this.faqs = res.data.faq_data || [];
					this.numberOfShowedFaqs = this.faqs.length;
					this.numberOfFaqs = res.data.faqs;
					this.numberOfPages = res.data.pages;
				}
			},
			setPage(n) {
				this.page = n;
			},
			async removeFaq(post) {
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete this notice?",
                    confirmButtonText: 'Yes, delete it!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {
							let res = await this.deleteFaq(post.id);

							return res;

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then(async (result) => {
					if (result.isConfirmed) {

						this.$swal(
							'Deleted!',
							"Faq has been deleted!",
							'success',
						)
						.then(() => {
							this.requestResolved = false;

							this.setFaqs();
						});
					}
                });
			},
			async bulkDelete () {


				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete all selected news and notices?",
                    confirmButtonText: 'Yes, delete!'
                }).then(async (result) => {

					if (result.isConfirmed) {
						this.requestResolved = false;

						let self = this;
						let faqs = this.faqs;

						await Promise.all(faqs.map( async function(post){

							if (post.selected) {
								if (post.type == 'tw_alerts') {
									await self.deleteAlert(post.id);
								} else {

									if (self.site_type == 'wordpress') {
										await self.deletePost(post.id);
									} else if(self.site_type == 'cms6') {
										await self.deleteFaq(post.id);
									}
								}
							}

						}));

						this.setFaqs();

						this.$swal(
							'Deleted!',
							"Notices have been deleted!",
							'success',
						);

					}
                });
			},
			setOrder(orderBy) {
				if (this.orderBy != orderBy) {
					this.order = 'desc';
				} else if (this.orderBy == orderBy && this.order == 'desc') {
					this.order = 'asc';
				} else if (this.orderBy == orderBy && this.order == 'asc') {
					this.order = 'desc';
				}
				this.orderBy = orderBy;
			},
			countSelected(index, event) {

				if (event.target.checked) {
					this.faqs[index].selected = true;
				} else {
					this.faqs[index].selected = false;
				}

				this.selected = this.faqs.filter(function(post) {return post.selected}).length;
			},
			openCreateFaqModal(event, faqId = null) {
				if (faqId) {this.editing = true;}
				this.editingFaqId = faqId;
				this.$refs.CreateFaqModal.showModal(true);
			},
			handleModalHidden() {
				this.editing = false;
				this.editingFaqId = null;
			},
			handleFaqSaved(action) {
				this.$swal(
					`Post ${action}!`,
					`Post ${action} successfully!`,
					'success',
				)
				.then(() => {

					this.requestResolved = false;
					this.setFaqs();
				});
			}
		},
		mixins: mixins
    }
</script>
