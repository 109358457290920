<!-- Modal -->
<template>
    <div class="modal fade" id="createFaqModal" ref="createFaqModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog" :class="mediaLibOpened ? 'modal-xl' : 'modal-lg'">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="d-flex">
                        <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span class="d-inline-flex">
                            <div v-if="!editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Create Faq</h5>
                                <p class="col-12 mb-0">Create a faq for your site</p>
                            </div>
                            <div v-if="editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Edit "{{ sliceString( title ) }}" faq</h5>
                                <p class="col-12 mb-0">Edit the contents of this faq</p>
                            </div>
                        </span>
                    </div>
                </div>

                <p class="text-center mt-3" v-show="editing && !faq.title">Loading faq details <i class="fa fa-spinner fa-spin"></i></p>

                <form v-show="!editing || (editing && faq && faq.title)" @submit.prevent.stop="storeNewFaq">
                    <div class="modal-body" id="modal-body">
                        <div v-show="sendError" class="alert alert-danger" role="alert">Error: Faq is not saved.</div>
                        <div class="row">
                            <div class="col">

                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group mb-4">
                                            <label for="faq-title" class="mb-2">Title</label>
                                            <input type="text" class="form-control mb-4" v-model="title" required id="faq-title" placeholder="Title of the faq">

                                            <div v-if="categories && categories.length">
                                                <label for="faq-category" class="mb-2">Category</label>
                                                <select class="form-control mb-4" v-model="category_id" id="faq-category">
                                                    <option v-for="category in categories" :value="category.id" v-html="category.name" :key="category.id"></option>
                                                </select>
                                            </div>

                                            <label for="faq-category" class="mb-2">Status</label>
                                            <select class="form-control mb-4 text-capitalize" required v-model="status" id="faq-status">
                                                <option v-for="status in statuses" :value="status" v-html="status" :key="status"></option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="col-xl-6 d-flex flex-column">
                                        <label class="mb-sm-3">Featured Image</label>
                                        <div class="row flex-grow-1 align-items-center mb-sm-3">
                                            <div class="col-md-6 mb-3 d-flex justify-content-center">
                                                <div class="position-relative">
                                                    <label for="featured_media" class="d-flex tw-modal-featured-img cursor-pointer align-items-center justify-content-center">
                                                        <img v-if="featuredMedia" :src="( featuredMedia && featuredMedia.media_details && featuredMedia.media_details.sizes && Object.keys(featuredMedia.media_details.sizes).length ) ? featuredMedia.media_details.sizes.thumbnail.source_url : featuredMedia.source_url || defaultImage" :alt="faq.title" height="175" width="188">
                                                        <span v-else class="d-flex align-items-center justify-content-center">
                                                            <span v-html="defaultImage"></span>
                                                        </span>
                                                    </label>

                                                    <div v-if="imageUploads" id="tw-modal-featured-img-loading-overlay" class="position-absolute opacity-25 bg-dark top-0 start-0 end-0 bottom-0 opacity-75 tw-light-grey-bg-color d-flex align-items-center justify-content-center">
                                                        <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                                                    </div>
                                                    <div v-if="featuredMedia" id="tw-modal-featured-img-overlay" class="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center cursor-pointer" @mouseover="hoverOverlay = true" @mouseleave="hoverOverlay = false" :class="{'tw-modal-featured-img-overlay-hovered': hoverOverlay}">
                                                        <div class="d-flex">
                                                            <label for="featured_media" class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 me-1 cursor-pointer">
                                                                <i class="fas fa-image fa-2x text-white"></i>
                                                            </label>
                                                            <span class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 ms-1 cursor-pointer" @click="faq.featured_media = null; featuredMedia = null">
                                                                <i class="fas fa-trash fa-2x text-white"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input ref="featured_media" type="file" class="d-none" id="featured_media" @change="handleFileUpload($event)" @click="resetImageUploader" accept="image/png, image/jpg, image/jpeg">
                                            </div>
                                            <div class="col-md-6 mb-3 d-none d-md-flex justify-content-center">
                                                <div class="tw-modal-media-lib tw-light-grey-bg-color d-flex align-items-center justify-content-center cursor-pointer" @click="mediaLib($event, chooseFeaturedImg)">
                                                    <div class="tw-modal-media-lib-btn tw-grey-bg-color d-flex align-items-center text-white">
                                                        <i class="fas fa-plus"></i> <span class="ms-2">Media Library</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="row mb-2">
                                        <label class="col-md-6 d-flex align-items-center" for="post-content">Description</label>
                                    </div>
                                    <editor
                                    api-key=""
                                    v-model="faqContent"
                                    theme="advanced"
                                    theme_advanced_toolbar_location="bottom"
                                    :init="tinymcInit"
                                    @execCommand="tinyMCEMedLib"
                                    />
                                </div>
                            </div>
                            <MediaLibraryInModal ref="mediaLibrary" :mediaLibOpened="mediaLibOpened" @mediaLibClose="mediaLibOpened = false" @setMedia="handleSetFeaturedImage" @mediaInsert="handleMediaInsert" />
                        </div>
                    </div>


                    <div class="modal-footer"  :class="mediaLibOpened ? 'col-8' : 'col-12'">
                        <button class="btn tw-red-bg-color text-white">
                            Save
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                        <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="modal.hide()">Cancel</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <!-- <MediaModal ref="MediaModal" @mediaInsert="handleMediaInsert" @setMedia="handleSetFeaturedImage" /> -->
</template>
<script>
    import { Modal } from 'bootstrap';
    import MediaLibraryInModal from '@/components/media-library/MediaLibraryInModal';
    // import '@vueup/vue-quill/dist/vue-quill.bubble.css'
    import editor from '@tinymce/tinymce-vue'
    import tinymceConfig from '@/tinymce/config';
    import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
    import helperMixin from '@/mixins/helperMixin';
    import siteMixin from '@/mixins/siteMixin';
    import wordpressMixin from '@/mixins/wordpressMixin';
    import cms6Mixin from '@/mixins/cms6Mixin';

    let site = localStorage.getObject('site');

    let mixins;

    if (site.type == 'wordpress') {
        mixins = [wordpressMixin,siteMixin,helperMixin];
    } else if(site.type == 'cms6') {
        mixins = [cms6Mixin,siteMixin,helperMixin];
    }
    export default {
        name: 'CreateFaqModal',
        components: {
            MediaLibraryInModal,
            editor,
        },
        emits: ["modalHidden", "faqSaved"],
        data: () => ({
            modal: null,
            toast: null,
            title: '',
            faqContent: '',
            defaultImage: '<div class="tw-defaul-featured-img tw-light-grey-bg-color d-flex align-items-center"><i class="fas fa-image me-2"></i> <span>Upload Image</span></div>',
            featuredMedia: null,
            file: null,
            categories: null,
            category: null,
            category_id: null,
            spinner: false,
            faq: {},
            status: 'publish',
            statuses: ['publish', 'draft'],
            chooseFeaturedImg: true,
            format: 'LLL dd, Y, hh:mm a',
            mediaLibOpened: false,
            imageUploads: false,
            hoverOverlay: false,
            sendError: false,
            site_type: null,
        }),
        mounted() {
            this.site_type = this.getSiteType();
            this.modal = new Modal(this.$refs.createFaqModal)

            if (this.site_type == 'cms6') {
                this.statuses.push('future');
            }
            // this.showToast()

            let createFaqModal = document.getElementById('createFaqModal')

            // Prevent Bootstrap dialog from blocking focusin
            document.addEventListener('focusin', (e) => {
              if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
              }
            });

            let self = this;
            //hide.bs.modal
            createFaqModal.addEventListener('hide.bs.modal', function () {
                let el = document.querySelector('button[aria-label="More..."][aria-expanded="true"]');

                if (el) {
                    el.click();
                }
            });
            createFaqModal.addEventListener('hidden.bs.modal', function () {
                getTinymce().activeEditor.ui.hide();
                self.$emit('modalHidden');
                self.faq = {};
                self.title = '';
                self.faqContent = '';
                self.featuredMedia = null;
                self.file = null;
                self.category = null;
                self.sendError = false;
                self.mediaLibOpened = false;
            });
        },
        watch: {
            editingFaqId () {

                if (this.editingFaqId) {
                    this.setFaq();
                }
            },
        },
        methods: {
            showModal: function(value) {
                if (value) {
                    this.modal.show();
                    this.setCategories();
                }
            },
            async setFaq() {
                let res = await this.getFaq(this.$props.editingFaqId);

                this.faq = await res.data;

                this.title = this.faq.title;
                this.faqContent = this.faq.content;
                // console.log(this.faqContent)
                // this.$refs.editor.setHTML(this.faqContent);
                // this.$refs.editor.pasteHTML(this.faqContent);
                this.category = this.faq.categories[0];
                this.category_id = this.category ? this.category.id : null;
                this.status = this.faq.status;

                if(this.faq.featured_media && this.faq.featured_media.media_details && this.faq.featured_media.media_details.sizes && Object.keys(this.faq.featured_media.media_details.sizes).length) {
                    this.featuredMedia = this.faq.featured_media;
                }
            },
            async setCategories() {
                let res = await this.getFaqCategories();

                this.categories = await res.data;
            },
            async handleFileUpload(event) {

                if (event.target.files[0]) {

                    this.imageUploads = true;
                    this.file = event.target.files[0];

                    let res = await this.uploadFile(this.file, true);

                    if (res.data && res.data.type && res.data.type == 'error') {

                        let text = res.data.errors.file.join('<br><br>');

                        this.$swal({
                            toast: true,
                            showConfirmButton: true,
                            timer: 10000,
                            title: 'Invalid file!',
                            html: text,
                            position: "top-end",
                            icon: "error",
                            closeModal: true,
                            timerProgressBar: true,
                        });
                    } else {

                        this.featuredMedia = await res.data;
                    }


                    this.imageUploads = false;

                    if (this.mediaLibOpened) {
                        this.$refs.mediaLibrary.setMediaLibrary();
                    }
                }
            },
            async storeNewFaq() {

                this.spinner = true;
                let categories = [];
                if (this.category_id) {
                    categories.push(this.category_id);
                }

                let mediaId = null;
                let res;
                if (this.featuredMedia) { mediaId = this.featuredMedia.id }

                this.faq.title = this.title;
                this.faq.content = this.faqContent;
                this.faq.categories = categories;
                this.faq.status = this.status;
                this.faq.featured_media = mediaId;

                if (this.editingFaqId) {
                    res = await this.updateFaq(this.faq);
                } else {
                    res = await this.saveFaq(this.faq);
                }

                this.spinner = false;

                if (res.status === 201 || res.status === 200) {
                    let action = 'saved';
                    if (!this.editingFaqId) {
                        action = 'published';
                    }
                    this.modal.hide();
                    this.$emit('faqSaved', action);
                } else {
                    this.sendError = true;
                }
            },
            mediaLib(event, chooseFeaturedImg = false) {
                if (this.mediaLibOpened) {
                    this.mediaLibOpened = false;
                } else {
                    let allowMulti = !chooseFeaturedImg;
                    this.$refs.mediaLibrary.mediaLibOpen(true, chooseFeaturedImg, allowMulti, chooseFeaturedImg);
                    this.mediaLibOpened = true;
                }
            },
            tinyMCEMedLib(event) {
                // console.log(event)
                if (event.command === 'openMediaLib') {
                    this.mediaLib(false)
                }
            },
            handleMediaInsert(content) {

                getTinymce().activeEditor.insertContent(content);
            },
            handleSetFeaturedImage(image) {
                this.featuredMedia = image;
            },
            resetImageUploader() {
                this.$refs.featured_media.value = '';
            },
        },
        computed: {
            tinymcInit() {
                return tinymceConfig.config;
            },
        },
        props: {
            editing: Boolean,
            editingFaqId: Number,
        },
        mixins: mixins
    }
</script>
<style scoped>
    #tw-modal-featured-img-loading-overlay {
        border-radius: 10px;

    }
    #tw-modal-featured-img-overlay {
        transition: .3s;
        background: rgba(255, 255, 255, 0);
    }
    #tw-modal-featured-img-overlay * {
        opacity: 0;
        transition: .3s;
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered {
        background: rgba(255, 255, 255, .25);
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered * {
        opacity: 1;
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > span:hover,
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > label:hover {
        transform: scale(1.1);
    }
    #tw-modal-featured-img-overlay > div > span {
        width: 50px;
        height: 50px;
    }
    .collapsed {
      width: 0;
    }
    .anime {
      /*flex-shrink: 1;*/
      transition: all 300ms ease;
    }
    .tw-create-modal-media-lib-wrapper {
        border-left: 1px solid #E0E0E0;
    }
    .modal-xl {
        max-width: 90%;
    }
    .tw-checkbox label {
        margin-left: 35px;
    }
    .tw-checkbox label::before {
        top: calc(50% - 12px);
        left: -35px;
    }
    .tw-checkbox label::after {
        top: calc(50% - 6px);
        left: -29px;
    }
    @media only screen and (min-width: 992px) {
        .modal-lg {
            max-width: 900px;
        }
    }
    @media only screen and (max-width: 991.99px) {
        .modal-dialog {
            /*min-width: 90%;*/
        }
    }
</style>
